import React from 'react';
import ListData from './scripture/ListData';
import PlayerPage from './Player/PlayerPage';
import BlessList from './bless/BlessList';
import BuyBless from './buyBless/BuyBless';
import Order from './order/Order';
import SharePage from './sharePage/sharePage';
import DayBless from './dayBless/DayBless';
import Zen from './zen/Zen';
import AdPage from './scripture/ListDataADpage';
import Privacy from './Privacy';
import Terms from './Terms';
import AppIntro from './appIntro/appIntro';

import './index.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	HashRouter,
	withRouter,
} from 'react-router-dom';

function Home() {
	return (
		<div className="mainpage">
			<HashRouter>
				<Switch>
					<Route exact path="/" component={AppIntro} />
					<Route path="/listdata" component={ListData} />
					<Route path="/music/:buddhaname" component={PlayerPage} />
					<Route path="/bless-history" component={BlessList} />
					<Route path="/bless-buyblees" component={BuyBless} />
					<Route path="/bless-order" component={Order} />
					<Route path="/bless-share" component={SharePage} />
					<Route path="/bless-dayzon" component={DayBless} />
					<Route path="/bless-zen" component={Zen} />
					<Route path="/bless-adpage" component={AdPage} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/terms-conditions" component={Terms} />
				</Switch>
			</HashRouter>
		</div>
	);
}

export default Home;
