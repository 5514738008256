import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import LineShareIcon from '../../../images/lineShareIcon.png';
import { ReactComponent as PlayBtn } from '../../../svg/playBtn.svg';

class HandlePlayer extends Component {
	state = {
		url: null,
		pip: false,
		playing: true,
		controls: true,
		light: false, //視頻縮圖
		volume: 0.8,
		muted: false,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
		countTotal: localStorage.getItem('countTotal'), //聽經總數
		countOne: localStorage.getItem(this.props.scriptureID),
		display: false,
	};

	handlePlayBtnDisplay = () => {
		this.setState({ playing: true });
		this.setState({
			display: !this.state.display,
		});
	};

	ref = (player) => {
		this.player = player;
	};

	load = (url) => {
		this.setState({
			url,
			played: 0,
			loaded: 0,
			pip: false,
		});
	};

	handlePlayPause = () => {
		this.setState({ playing: !this.state.playing });
	};

	handlePlay = () => {
		console.log('onPlay');
		this.setState({ playing: true });
	};

	handleProgress = (state) => {
		console.log('onProgress', state);
		// We only want to update time slider if we are not currently seeking
		if (!this.state.seeking) {
			this.setState(state);
		}
	};

	handlePause = () => {
		console.log('onPause');
		this.setState({ playing: false });
	};

	handleEnded = () => {
		const ScriptureID = this.props.scriptureID;

		this.setState({
			display: !this.state.display,
		});

		this.setState((state) => ({ countTotal: state.countTotal++ + 1 }));
		this.setState((state) => ({ countOne: state.countOne++ + 1 }));

		localStorage.setItem('countTotal', this.state.countTotal);
		localStorage.setItem(ScriptureID, this.state.countOne);
	};

	// handleEnded = () => {
	// 	console.log('onEnded');
	// 	this.setState({ playing: this.state.loop });
	// };

	ref = (player) => {
		this.player = player;
	};

	render() {
		const { playing } = this.state;

		const fbVid =
			'https://www.facebook.com/104712598706875/videos/' + this.props.urlFB;

		const scriptureID = this.props.scriptureID;
		const title = this.props.title;
		const contents = this.props.contents;
		const shareContent = this.props.shareContent;
		const lineShareCount = localStorage.getItem(scriptureID);

		let playBtnDisplay = {
			display: this.state.display ? 'flex' : 'none',
		};

		return (
			<div className="playerView">
				<section className="section">
					<div className="playerDiv">
						<div
							className="playBtn"
							onClick={this.handlePlayBtnDisplay}
							style={playBtnDisplay}
						>
							<PlayBtn />
						</div>
						<div className="loadText">經文讀取中。。。</div>
						<div className="player-wrapper">
							<ReactPlayer
								ref={this.ref}
								className="react-player"
								width="100%"
								height="100%"
								url={fbVid}
								playing={playing}
								onReady={() => console.log('onReady')}
								onEnded={this.handleEnded}
								onPlay={this.handlePlay}
								onPause={this.handlePause}
								onSeek={(e) => console.log('onSeek', e)}
								onError={(e) => console.log('onError', e)}
								onProgress={this.handleProgress}
							/>
						</div>
					</div>
					<div className="shareContent">
						聽誦
						<span>《{title}》</span>第
						<span> {localStorage.getItem(scriptureID)} </span>
						遍
						<br />
						將此次經文功德迴向給你，{shareContent}
						<h4>{contents}</h4>
					</div>

					<div className="lineShare">
						<button
							onClick={() => {
								window.open(
									`line://msg/text/${encodeURIComponent(
										'在此聽誦《' +
											title +
											'》' +
											'第' +
											lineShareCount +
											'遍。願將此次經文功德迴向給你，' +
											shareContent +
											'https://www.mday.tw/#/bless-share'
									)}`,
									'_blank'
								);
							}}
						>
							<img src={LineShareIcon} alt="line share" />
							分享迴向給親友
						</button>
					</div>
				</section>
			</div>
		);
	}
}

export default HandlePlayer;
