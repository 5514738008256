import React, { useState, useEffect, useReducer } from 'react';

function DayBless() {
	const formReducer = (state, event) => {
		return {
			...state,
			[event.name]: event.value,
		};
	};

	const [formData, setFormData] = useReducer(formReducer);
	const handleChange = (e) => {
		setFormData({
			name: e.target.name,
			value: e.target.value,
		});
	};

	const [orderDate, setOrderDate] = useState('');
	const handleDate = () => {
		let myDate = new Date();
		let tYear = myDate.getFullYear();
		let tMonth = myDate.getMonth();
		let tDay = myDate.getDate();
		let m = tMonth + 1;
		if (m.toString().length === 1) {
			m = '0' + m;
		}
		return setOrderDate(tYear + '年' + m + '月' + tDay + '日');
	};

	const [loopX, setloop] = useState(1);
	const handleSave = () => {
		setloop(loopX + 1);
		if (loopX >= 3) {
			return setloop(loopX === 1);
		}
		let countryString = JSON.stringify(formData);
		let timestamp = 'dayBless' + JSON.stringify(loopX);

		localStorage.setItem(timestamp, countryString);
	};

	const [data, setData] = useState([]);
	const getData = async () => {
		try {
			const res = await fetch(
				'https://sheets.googleapis.com/v4/spreadsheets/1o3f4BXqJmFhw-VQlw9YLeDUhLU3IgFqPl6K_EWZA-OU/values/product?alt=json&key=AIzaSyB0TFmLz4bq-4O1ydxGytM_RPErrfRkc6Y'
			);
			const data = await res.json();
			const dataX = data.values;
			// const dataS = dataX.slice(1);
			const item = dataX[Math.floor(Math.random() * dataX.length)];
			const dataM = JSON.stringify(item);
			setData(JSON.parse(dataM));
			// console.log(dataS);
		} catch (error) {
			console.log(error);
		}
	};

	const getdate = () => {
		let date = new Date().setHours(24, 0, 0, 0);
		// let myDate = new Date();
		// let date = myDate.getDate();
		new Date() > date && alert('good');
		console.log(date);
	};

	useEffect(() => {
		handleDate();
		getdate();
		getData();
	}, []);

	return (
		<div>
			<h2>今天：{orderDate}</h2>
			<input name="nameM" defaultValue={data[0]} onChange={handleChange} />
			{/* <input name="introM" defaultValue={data[2]} onChange={handleChange} /> */}

			<h4>{loopX}</h4>
			<button onClick={handleSave}>ADD</button>

			<h1>{localStorage.getItem('dayBless1')}</h1>
		</div>
	);
}

export default DayBless;
