import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowBack } from '../../../svg/arrowBack.svg';
function backOrderPage(props) {
	const playTitle = props.playPageTitle;

	const backPageStyle = {
		position: 'sticky',
		display: 'flex',
		width: '100%',
		height: '44px',
		top: '0',
		left: '0',
		backgroundColor: '#630f10',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '999',
		fontSize: '14px',
		color: '#fff',
	};

	const backBtn = {
		width: '32px',
		height: '32px',
		position: 'absolute',
		left: '8px',
		fill: '#fff',
	};

	return (
		<>
			<div className="backPage" style={backPageStyle}>
				<Link to="/bless-buyblees" style={backBtn}>
					<ArrowBack />
				</Link>
				<div>{playTitle}</div>
			</div>
		</>
	);
}

export default backOrderPage;
