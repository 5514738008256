import React from 'react';

function DelModal({ handleDel, delModal, toDelete, onTotalAll, cost }) {
	const dele = () => {
		toDelete();
		onTotalAll(-cost);
	};

	return (
		<>
			<div
				className={`modalOpacity ${delModal ? 'modalOpacityOpen' : ''}`}
				onClick={handleDel}
			></div>
			<div className={`delModalClose ${delModal ? 'delModalOpen' : ''}`}>
				<div>
					<h4>是否確定要移除此登記對象？</h4>
					<div style={{ display: 'flex' }}>
						<div className="delYes" onClick={dele}>
							刪除
						</div>
						<div className="delNo" onClick={handleDel}>
							保留
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DelModal;
