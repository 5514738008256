import React from 'react';
import { ReactComponent as BookMake } from '../../../svg/bookmark.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../../../svg/arrowBack.svg';
function backBuyPage(props) {
	const playTitle = props.playPageTitle;

	const backPageStyle = {
		position: 'sticky',
		display: 'flex',
		width: '100%',
		height: '44px',
		top: '0',
		left: '0',
		backgroundColor: '#630f10',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '999',
		fontSize: '14px',
		color: '#fff',
	};

	const backBtn = {
		width: '32px',
		height: '32px',
		position: 'absolute',
		left: '8px',
		fill: '#fff',
	};

	const orderHistory = {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		right: '16px',
		fontWeight: 'bold',
		color: '#fff',
	};

	const bookmark = {
		width: '18px',
		height: '18px',
		fill: '#fff',
	};

	return (
		<>
			<div className="backPage" style={backPageStyle}>
				{/* <Link to="/bless-history" style={backBtn}>
					<ArrowBack />
				</Link> */}
				<div>{playTitle}</div>
				<Link style={orderHistory} to="/bless-order">
					<span style={bookmark}>
						<BookMake />
					</span>
					<span>觀看登記</span>
				</Link>
			</div>
		</>
	);
}

export default backBuyPage;
