import React, { useState, useRef } from 'react';
import BackHistoryPage from '../../backHistoryPage';
import { dataBless } from './blessData';
import blessBanner from '../../../images/blessBanner.jpg';
import { ReactComponent as ArrowRight } from '../../../svg/arrowRight.svg';
import { Link } from 'react-router-dom';
import BlessItem from './BlessItem';

function BlessList() {
	const ownChange = useRef();
	const [blessList, setBlessList] = useState('0');
	const handleBlessList = (index) => {
		if (blessList === index) {
			return setBlessList('0');
		}
		setBlessList(index);
	};

	const [own, setOwn] = useState();
	const handleOwn = () => {
		if (own) {
			return setOwn();
		}
		setOwn('0');
	};

	return (
		<>
			<BackHistoryPage playPageTitle="廟宇巡禮誦經祈福" />

			<div className="blessBanner">
				<img src={blessBanner} alt="誦經祈福" />
			</div>

			<div className="blessIntro">
				鑑於歷史發展緣故，若論全台香火最為鼎盛、神蹟最為彰顯之處，非府城莫屬，禮請師父於府城廟宇巡禮持念，為師兄師姐們消災祈福。
				<div className="ownHistory">
					<div
						className={`ownHistoryBtn ${own ? 'ownHistoryBtnClick' : ''}`}
						onClick={handleOwn}
						id="testopen"
					>
						<ArrowRight
							className={`arrowHistory ${own ? 'arrowUpHistory' : ''}`}
						/>
						如何觀看自己的祈福紀錄？
					</div>
					<div
						onClick={handleOwn}
						className="ownHistoryContent"
						style={
							own
								? { height: ownChange.current.scrollHeight }
								: { height: '0px' }
						}
						ref={ownChange}
					>
						<div>
							每月師父會將您的疏文帶至廟宇誦經祈福，依照你登記在疏文上的資料唱名迴向、過香火加持，並代你供花果、添香油、燃香燒金謝恩。
							<br />
							<br />
							<span>觀看每月誦經祈福紀錄：</span>
							<br />
							為保護個資不會公開展示，若要觀看自己的紀錄，請傳訊息告知登記的月份與姓名，即會將記錄傳送給您。
						</div>
					</div>
				</div>
			</div>

			<ul className="blessUl">
				{dataBless.map((blessData, index) => (
					<BlessItem
						key={index}
						blessDataAll={blessData}
						toToggle={() => handleBlessList(index)}
						active={blessList === index}
					/>
				))}
			</ul>

			<Link
				className="enterNameDiv"
				to={{
					pathname: '/bless-buyblees',
				}}
			>
				<button className="enterName">登記報名</button>
			</Link>
			<div className="bottomSpace"></div>
		</>
	);
}

export default BlessList;
