export const videoData = [
	{
		videoGet: 'image/video/rain.mp4',
	},
	{
		videoGet: 'image/video/fire.mp4',
	},
	{
		videoGet: 'image/video/buddha.mp4',
	},
	{
		videoGet: 'image/video/forest.mp4',
	},
	{
		videoGet: 'image/video/mountain.mp4',
	},
	{
		videoGet: 'image/video/sea.mp4',
	},
	{
		videoGet: 'image/video/temple.mp4',
	},
	{
		videoGet: 'image/video/wind.mp4',
	},
];
export default {
	videoData,
};
