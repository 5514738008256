export const dataHeart = [
	{
		title: '大悲咒',
		imgTitle: 'image/heartBig.png',
		content:
			'大悲咒為觀世音菩薩慈悲眾生之大悲願力，此咒能除一切病苦災難、延壽得福、常遇貴人、財食豐足、不受劫財等十五種善生。',
		listImg: 'image/hearBuddha1.jpg',
		link: '/2699134427058736',
		id: 'scriptureHeartID1',
		share: '為你除一切病苦災難，延壽得福得十五種善生。',
	},
	{
		title: '百字明咒',
		imgTitle: 'image/heartBai.png',
		content:
			'百字明是密宗咒語，也是金剛持菩薩的咒語，用於消除罪障、懺悔、補闕，被稱為“一切懺悔之王“。',
		listImg: 'image/hearBuddha2.jpg',
		link: '/444032930723033',
		id: 'scriptureHeartID2',
		share: '願金剛菩薩為你消除過去罪障今世得福。',
	},
	{
		title: '大佛頂首楞嚴神咒',
		imgTitle: 'image/heartLiu.png',
		content:
			'愣嚴咒為佛教中最長的咒，亦被稱為咒中之王。聽聞此咒能即生福德，避免一切災難，所求所願皆得圓滿。',
		listImg: 'image/nameBuddha1.jpg',
		link: '/456934272514547',
		id: 'scriptureHeartID3',
		share: '為你消除一切災難，使你所求如願。',
	},
	{
		title: '地藏菩薩滅定業真言',
		imgTitle: 'image/heartMie.png',
		content:
			'得聞此地藏菩薩法身印咒(俗稱 滅定業真言)者，現世乃皆得地藏菩薩及一切諸佛攝受加持與護念，得一切世間善緣。',
		listImg: 'image/classBuddha3.jpg',
		link: '/445930283907916',
		id: 'scriptureHeartID4',
		share: '得諸佛加持與護念，得一切世間善緣。',
	},
	{
		title: '六字大明咒',
		imgTitle: 'image/heartSix.png',
		content:
			'俗稱六字真言，聽聞誦念能受無量諸佛菩薩為之灌頂，得清淨智聚，開利眾事業之寶藏，是漢傳佛教與密宗的無上心咒。',
		listImg: 'image/nameBuddha3.jpg',
		link: '/6995457560471823',
		id: 'scriptureHeartID5',
		share: '助你開利眾事業之寶藏。',
	},
	{
		title: '金剛薩埵心咒',
		imgTitle: 'image/heartGold.png',
		content:
			'此為密宗的咒語，薩埵也是菩薩的意思，金剛薩埵為消業滅罪的主尊，常聽誦可破除煩惱，止諸惡念，生諸功德。',
		listImg: 'image/nameBuddha4.jpg',
		link: '/650385789328677',
		id: 'scriptureHeartID6',
		share: '願你破除煩惱生信心。',
	},
	{
		title: '文殊菩薩心咒',
		imgTitle: 'image/heartWen.png',
		content:
			'這是文殊菩薩增長智慧的咒語，時常聽誦，可以增長福德智慧增強記憶力，勝願成就，福智具足得十八種功德。',
		listImg: 'image/classBuddha5.jpg',
		link: '/2021507191345716',
		id: 'scriptureHeartID7',
		share: '增長福德智慧凡考試皆順利。',
	},
	{
		title: '蓮花生大士心咒',
		imgTitle: 'image/heartLian.png',
		content:
			'蓮花生大士集智慧、慈悲和伏惡的力量於一身，擁有無邊的法力，祈禱蓮師有不可思議的功德，一切重大事業均可獲蓮師的加持。',
		listImg: 'image/classBuddha4.jpg',
		link: '/633538124661776',
		id: 'scriptureHeartID8',
		share: '願你一切重大事業均可獲蓮師加持。',
	},
];
