import React from 'react';
import ShareNavBar from './ShareNavbar';
import BannerShareImg from '../../../images/bannerShare.jpg';
import LineShareIcon from '../../../images/lineShareIcon.png';
import Buddha from '../../../images/sharePageBuddha.png';
import { ReactComponent as ProductDec } from '../../../svg/productModalDec.svg';
import './sharePage.css';

function sharePage() {
	return (
		<div>
			<ShareNavBar />
			<div className="banner">
				<img src={BannerShareImg} alt="bannerImg" />
			</div>
			<div className="sharePageContent">
				<div>
					<span className="decLeft">
						<ProductDec />
					</span>
					<span className="decRight">
						<ProductDec />
					</span>
					您收到親友的經文迴向
					<br />
					<span>您也可聽經迴向，將這份善傳遞下去</span>
					<span className="decLeftDown">
						<ProductDec />
					</span>
					<span className="decRightDown">
						<ProductDec />
					</span>
				</div>
			</div>

			<div className="sharePageIntro">
				<div>善園 LINE 佛經樂曲</div>
				<p>
					<span>｜</span>諸多經典，一鍵聽誦<span>｜</span>
				</p>

				<p>
					<span>｜</span>動態經文，邊看邊聽<span>｜</span>
				</p>

				<p>
					<span>｜</span>聽經計數，日日積累<span>｜</span>
				</p>

				<p>
					<span>｜</span>迴向親友，消災得福<span>｜</span>
				</p>
			</div>

			<div className="shareSpace">
				<img src={Buddha} alt="佛像" />
			</div>

			<div className="lineFixedDiv">
				<button
					className="lineShareFixed"
					onClick={() => {
						window.open('https://lin.ee/RDN9UHU', '_blank');
					}}
				>
					<img src={LineShareIcon} alt="line share" />
					進入佛經樂曲 開始聽誦
				</button>
			</div>
		</div>
	);
}

export default sharePage;
