import React, { useState, useReducer, useEffect } from 'react';
import { ReactComponent as Alert } from '../../../svg/alert.svg';
import BackBuyPage from './backBuyPage';
// import blessBanner from '../../../images/buyBanner.jpg';
import blessBanner from '../../../images/banner2023.jpg';
import BottomImg from '../../../images/sharePageBuddha.png';
import tancard from '../../../images/tancard.jpg';
import './buyBless.css';
import FormInput from './FormInput';
import PaymentInfo from './PaymentInfo';
import FormInputFirst from './FormInputFirst';
import { Link } from 'react-router-dom';

function BuyBless() {
	const formReducer = (state, event) => {
		if (event.reset) {
			return {
				['entry.812450364']: '',
				['entry.1147185105']: '',
			};
		}
		return {
			...state,
			[event.name]: event.value,
		};
	};

	//費用總計
	const [selectedValue, setSelectedValue] = useState(12);
	const [totalCash, setTotalCash] = useState(0);
	const totalAll = (price) => {
		setTotalCash(totalCash + price);
	};

	//取總費用的值

	const [checkContentAll, setCheckContentAll] = useState('');
	const handleTotalCost = (e) => {
		// e.preventDefault();
		let input = document.querySelector('#totalCost');
		let checkContentAll = totalCash * selectedValue;

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(input, checkContentAll);

		var ev2 = new Event('input', { bubbles: true });
		input.dispatchEvent(ev2);

		setCheckContentAll(checkContentAll);
	};

	const [formData, setFormData] = useReducer(formReducer, {
		'entry.131897869': '12個月',
	});
	const handleChange = (e) => {
		setFormData({
			name: e.target.name,
			value: e.target.value,
		});
	};

	//移除訂單時減掉單個小計費用
	const [cost, setCost] = useState(0);

	// 儲存到localStorage;
	const handleSave = () => {
		let countryString = JSON.stringify(formData);
		let times = new Date();
		let timestamp = 'buyData' + Date.parse(times);
		localStorage.setItem(timestamp, countryString);
	};

	//驗證資料欄位
	const [productIn, setProductIn] = useState(false);
	const [nameIn, setNameIn] = useState(false);
	const [birthdayIn, setBirthdayIn] = useState(false);
	// const [addressIn, setAddressIn] = useState(false)

	//送出資料
	const [submitting, setSubmitting] = useState(false);
	const handleSubmit = (e) => {
		// e.preventDefault();
		const product = document.getElementsByName('entry.1806381009');
		const name = document.getElementsByName('entry.812450364');
		const birthday = document.getElementsByName('entry.1147185105');
		// const address = document.getElementsByName('entry.1147185105');
		for (var i = 0; i < product.length; i++) {
			if (product[i].value === '' || product[i].value === null) {
				return (
					setProductIn(true) +
					setTimeout(() => {
						setProductIn(false);
					}, 3000)
				);
			}
			if (name[i].value === '' || name[i].value === null) {
				return (
					setNameIn(true) +
					setTimeout(() => {
						setNameIn(false);
					}, 3000)
				);
			}
			if (birthday[i].value === '' || birthday[i].value === null) {
				return (
					setBirthdayIn(true) +
					setTimeout(() => {
						setBirthdayIn(false);
					}, 3000)
				);
			}
		}
		setSubmitting(true);
		setTimeout(() => {
			handleSave();
			setSubmitting(false);
			setFormData({
				reset: true,
			});
		}, 3000);
		handleSendModal();
	};

	//送出資料後跳POPUP確認視窗
	const [seedModal, setSeedModal] = useState();
	const handleSendModal = () => {
		if (seedModal) {
			return setSeedModal();
		}
		setSeedModal('0');
	};
	//新增祈福欄位
	const [dynamicFormItem, setDynamicFormItem] = useState([]);
	const addDynamicForm = (e) => {
		e.preventDefault();
		const product = document.getElementsByName('entry.1806381009');
		const name = document.getElementsByName('entry.812450364');
		const birthday = document.getElementsByName('entry.1147185105');
		// const address = document.getElementsByName('entry.1147185105');
		for (var i = 0; i < product.length; i++) {
			if (product[i].value === '' || product[i].value === null) {
				return (
					setProductIn(true) +
					setTimeout(() => {
						setProductIn(false);
					}, 3000)
				);
			}
			if (name[i].value === '' || name[i].value === null) {
				return (
					setNameIn(true) +
					setTimeout(() => {
						setNameIn(false);
					}, 3000)
				);
			}
			if (birthday[i].value === '' || birthday[i].value === null) {
				return (
					setBirthdayIn(true) +
					setTimeout(() => {
						setBirthdayIn(false);
					}, 3000)
				);
			}
		}
		const newDynamicForm = dynamicFormItem.concat([Date.now()]);
		setDynamicFormItem(newDynamicForm);
		console.log(newDynamicForm);
	};

	const removeDynamicFormItem = (index) => {
		let tempDynamicFormItem = [...dynamicFormItem];
		tempDynamicFormItem.splice(index, 1);
		setDynamicFormItem(tempDynamicFormItem);
		setTotalCash(totalCash - cost);
		console.log(tempDynamicFormItem);
	};

	//組合姓名
	const [nameCombo, setNameCombo] = useState('');
	const handleNameCombo = () => {
		var obc = document.getElementsByName('entry.812450364');
		var values = '';
		for (var i = 0; i < obc.length; i++) {
			values += obc[i].value + '・';
		}
		let d = document.getElementById('nameCombo').value;
		d = values;
		setNameCombo(d);
	};
	const [getNameCombo, setGetNameCombo] = useState('');
	const handleGetNameCombo = (e) => {
		// e.preventDefault();
		let nameInput = document.querySelector('#nameCombo');
		let getNameValue = nameCombo + ' ';

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(nameInput, getNameValue);
		var ev2 = new Event('input', { bubbles: true });
		nameInput.dispatchEvent(ev2);
		setGetNameCombo(getNameValue);
	};

	//組合產品
	const [productCombo, setProductCombo] = useState('');
	const handleProductCombo = () => {
		var obc = document.getElementsByName('entry.1806381009');
		var values = '';
		for (var i = 0; i < obc.length; i++) {
			values += obc[i].value + '｜';
		}
		let d = document.getElementById('productCombo').value;
		d = values;
		setProductCombo(d);
	};
	const [getProductCombo, setGetProductCombo] = useState('');
	const handleGetProductCombo = (e) => {
		// e.preventDefault();
		let productInput = document.querySelector('#productCombo');
		let getProductValue = productCombo + ' ';

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(productInput, getProductValue);
		var ev2 = new Event('input', { bubbles: true });
		productInput.dispatchEvent(ev2);
		setGetProductCombo(getProductValue);
	};

	//組合生日
	const [birthdayCombo, setBirthdayCombo] = useState('');
	const handleBirthdayCombo = () => {
		var obc = document.getElementsByName('entry.1147185105');
		var values = '';
		for (var i = 0; i < obc.length; i++) {
			values += obc[i].value + '・';
		}
		let d = document.getElementById('birthdayCombo').value;
		d = values;
		setBirthdayCombo(d);
	};
	const [getBirthdayCombo, setGetBirthdayCombo] = useState('');
	const handleGetBirthdayCombo = (e) => {
		// e.preventDefault();
		let birthdayInput = document.querySelector('#birthdayCombo');
		let getBirthdayValue = birthdayCombo + ' ';

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(birthdayInput, getBirthdayValue);
		var ev2 = new Event('input', { bubbles: true });
		birthdayInput.dispatchEvent(ev2);
		setGetBirthdayCombo(getBirthdayValue);
	};

	//組合地址
	const [addressCombo, setAddressCombo] = useState('');
	const handleAddressCombo = () => {
		var obc = document.getElementsByName('entry.790714652');
		var values = '';
		for (var i = 0; i < obc.length; i++) {
			values += obc[i].value + '・';
		}
		let d = document.getElementById('addressCombo').value;
		d = values;
		setAddressCombo(d);
	};
	const [getAddressCombo, setGetAddressCombo] = useState('');
	const handleGetAddressCombo = (e) => {
		// e.preventDefault();
		let addressInput = document.querySelector('#addressCombo');
		let getAddressValue = addressCombo + ' ';

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(addressInput, getAddressValue);
		var ev2 = new Event('input', { bubbles: true });
		addressInput.dispatchEvent(ev2);
		setGetAddressCombo(getAddressValue);
	};

	//取得年月日
	const [orderDate, setOrderDate] = useState('');
	const [keyDate, setKeyDate] = useState('');
	const handleDate = () => {
		let myDate = new Date();
		let tYear = myDate.getFullYear();
		let tMonth = myDate.getMonth();
		let tDay = myDate.getDate();
		let m = tMonth + 1;
		const orderKeyOnly = Date.now();
		if (m.toString().length === 1) {
			m = '0' + m;
		}
		return (
			setOrderDate(tYear + '年' + m + '月' + tDay + '日') +
			setKeyDate(orderKeyOnly)
		);
	};
	const [dateX, setDateX] = useState('');
	const [keyX, setKeyX] = useState('');
	const handleGetDate = () => {
		let dateInput = document.querySelector('#orderDate');
		let getDateValue = orderDate;

		let keyInput = document.querySelector('#orderKey');
		let getKeyValue = keyDate;

		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set;
		nativeInputValueSetter.call(dateInput, getDateValue);
		var ev2 = new Event('input', { bubbles: true });
		dateInput.dispatchEvent(ev2);
		setDateX(getDateValue);

		nativeInputValueSetter.call(keyInput, getKeyValue);
		var ev2 = new Event('input', { bubbles: true });
		keyInput.dispatchEvent(ev2);
		setKeyX(getKeyValue);
	};

	useEffect(() => {
		handleTotalCost();
		handleProductCombo();
		handleGetProductCombo();
		handleNameCombo();
		handleBirthdayCombo();
		handleAddressCombo();
		handleGetDate();
		return () => {
			// handleGetProductCombo();
		};
	}, [totalCash, productCombo]);

	useEffect(() => {
		handleGetNameCombo();
		handleDate();
		return () => {};
	}, [nameCombo]);
	useEffect(() => {
		handleGetBirthdayCombo();
		return () => {};
	}, [birthdayCombo]);
	useEffect(() => {
		handleGetAddressCombo();
		return () => {};
	}, [addressCombo]);

	return (
		<div className="buyBG">
			<BackBuyPage playPageTitle="登記" />
			<div className="blessBanner">
				<img src={blessBanner} alt="登記報名" />
			</div>

			<iframe
				name="iframe_redirect"
				id="iframe_redirect"
				style={{ display: 'none' }}
			></iframe>
			<form
				target="iframe_redirect"
				action="https://docs.google.com/forms/d/e/1FAIpQLSclLtTeWd6x1LpCeyiWxwnI8c_lkrLSsgE_uEY8lg9BUitLpg/formResponse"
				method="post"
			>
				<div className="buyDiv">
					<div className="titleText">
						填寫祈福對象資料
						<p>
							資料會寫在疏文上，師父會手持疏文持念唱名迴向，填寫後請再次確認，感恩。
						</p>
					</div>
					<FormInputFirst
						onTotalAll={totalAll}
						formData={formData}
						handleChange={handleChange}
						cost={cost}
						setCost={setCost}
						handleTotalCost={handleTotalCost}
						handleNameCombo={handleNameCombo}
						handleProductCombo={handleProductCombo}
						handleGetProductCombo={handleGetProductCombo}
						handleBirthdayCombo={handleBirthdayCombo}
						handleAddressCombo={handleAddressCombo}
					/>

					{dynamicFormItem.map((item, index) => {
						return (
							<div key={item} style={{ marginTop: 26 }}>
								<FormInput
									toDelete={() => removeDynamicFormItem(index)}
									onTotalAll={totalAll}
									handleChange={handleChange}
									handleTotalCost={handleTotalCost}
									totalCash={totalCash}
									handleNameCombo={handleNameCombo}
									handleProductCombo={handleProductCombo}
									handleGetProductCombo={handleGetProductCombo}
									handleBirthdayCombo={handleBirthdayCombo}
									handleAddressCombo={handleAddressCombo}
								/>
							</div>
						);
					})}
					<button className="addBlessUse" onClick={addDynamicForm}>
						添加對象 <span>＋</span>
					</button>
				</div>

				<PaymentInfo
					formData={formData}
					handleChange={handleChange}
					setSelectedValue={setSelectedValue}
					selectedValue={selectedValue}
					handleTotalCost={handleTotalCost}
					totalAll={totalAll}
				/>
				<div className="totalDiv">
					<label>
						總計：
						<input
							name="entry.589267785"
							readOnly="readonly"
							type="text"
							value={checkContentAll}
							className="totalCost"
							id="totalCost"
							onChange={handleChange}
						/>
					</label>
					<div className="submitDiv">
						<input
							type="submit"
							value="送出"
							id="submit_btn"
							className="submitBtn"
							// disabled={formData['entry.790714652'] === formData.value}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</form>
			{/* <div className="bottomSpace"></div> */}
			{/* SEND MODAL CHECK */}
			<Link to="/bless-order">
				<div
					className={`modalOpacity ${seedModal ? 'modalOpacityOpen' : ''}`}
					onClick={handleSendModal}
				></div>
			</Link>
			<div className={`delModalClose ${seedModal ? 'delModalOpen' : ''}`}>
				<div>
					<h4>已完成登記</h4>
					{submitting && <h4>登記中，請稍候...</h4>}
					<Link to="/bless-order">
						<div style={{ display: 'flex' }}>
							<div className="delNo" onClick={handleSendModal}>
								完成
							</div>
						</div>
					</Link>
				</div>
			</div>

			<img className="buyPageImg" src={tancard} alt="佛祖" />
			<img className="buyPageImg" src={BottomImg} alt="佛祖" />

			{/* 驗證 INPUT 欄位 MODAL */}
			{productIn && (
				<div className="inputNullModalDiv">
					<div className="inputNullModal">
						<p>
							<Alert className="inputNullIcon" />
							您尚未選擇祈福項目
						</p>
					</div>
				</div>
			)}
			{nameIn && (
				<div className="inputNullModalDiv">
					<div className="inputNullModal">
						<p>
							<Alert className="inputNullIcon" />
							請填寫一位姓名
						</p>
					</div>
				</div>
			)}
			{birthdayIn && (
				<div className="inputNullModalDiv">
					<div className="inputNullModal">
						<p>
							<Alert className="inputNullIcon" />
							請填寫祈福對象生日
						</p>
					</div>
				</div>
			)}
			{/* 姓名組合 */}
			<input
				style={{ display: 'none' }}
				id="nameCombo"
				name="nameAll"
				value={nameCombo}
				onClick={handleNameCombo}
				onChange={handleChange}
			/>
			{/* 產品組合 */}
			<input
				style={{ display: 'none' }}
				id="productCombo"
				name="productAll"
				value={productCombo}
				onClick={handleProductCombo}
				onChange={handleChange}
			/>
			{/* 生日組合 */}
			<input
				style={{ display: 'none' }}
				id="birthdayCombo"
				name="birthdayAll"
				value={birthdayCombo}
				onClick={handleBirthdayCombo}
				onChange={handleChange}
			/>

			{/* 地址組合 */}
			<input
				style={{ display: 'none' }}
				id="addressCombo"
				name="addressAll"
				value={addressCombo}
				onClick={handleAddressCombo}
				onChange={handleChange}
			/>
			{/* 訂單日期 */}
			<input
				style={{ display: 'none' }}
				onClick={handleDate}
				onChange={handleChange}
				name="orderDate"
				value={dateX}
				id="orderDate"
			/>
			{/* localStorage Key */}
			<input
				style={{ display: 'none' }}
				value={keyX}
				onChange={handleChange}
				name="keyDate"
				id="orderKey"
			/>
		</div>
	);
}

export default BuyBless;
