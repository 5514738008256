import React, { useState, lazy, Suspense } from 'react';
import BackZenNav from './backZenPage';
import './zen.css';
import rainIcon from '../../../video/rain.png';
import rainBlur from '../../../video/rainBlur.jpg';
import rainMp3 from '../../../video/audio/rain.mp3';

import { videoData } from './videoData';

const SelectZen = lazy(() => import('./SelectZen'));
const ZenTime = lazy(() => import('./ZenTime'));

const { Gapless5 } = require('@regosen/gapless-5');
const playAudio = new Gapless5({
	tracks: rainMp3,
	loop: true,
	singleMode: true,
	exclusive: true,
	useHTML5Audio: false,
});

function Zen() {
	const [zenStart, setZenStart] = useState(false);
	const [readyPop, setReadyPop] = useState(false);
	const [startBtn, setStartBtn] = useState(false);
	const [iconSH, setIconSH] = useState(false);
	const [videoPush, setVideoPush] = useState(videoData[0].videoGet);
	const [zenIcon, setZenIcon] = useState(rainIcon);
	const [poster, setPoster] = useState(rainBlur);

	//初始提示
	const handleZenStart = () => {
		setZenStart(!zenStart);
		setIconSH(!iconSH);
		playAudio.play();
	};
	const handleAudioStop = () => {
		playAudio.pause();
	};
	//開始按鈕
	const handleCountStart = () => {
		setReadyPop(!readyPop);
		setIconSH(!iconSH);
		setStartBtn(!startBtn);
	};
	//切換情境
	const [zenModal, setZenModal] = useState(false);
	const handleZenModal = () => {
		setZenModal(!zenModal);
		setIconSH(!iconSH);
		setStartBtn(!startBtn);
	};

	return (
		<>
			<BackZenNav playPageTitle="一心淨土" handleAudioStop={handleAudioStop} />
			<div className={`startZen ${startBtn ? 'startZenClose' : ''}`}>
				<div onClick={handleCountStart}>開始</div>
			</div>

			<div
				className={`zenStart ${zenStart ? 'zenStartClose' : ''}`}
				onClick={handleZenStart}
			>
				<div className="zenFirst">
					<div className="zenFirstContent">
						<div className="zenFirstText">
							<h3>每日淨心三分鐘</h3>
							你可以切換不同的情境
							<br />
							藉由畫面與聲音，配合呼吸引導，清除雜念放鬆思緒，吸引宇宙天地間的好能量。
							<br />
							<br />
							<div>
								請開啟聲音
								<br />
								點擊畫面開始清心淨念
							</div>
						</div>
					</div>
					<div className="zenModalBG"></div>
				</div>
			</div>
			<div
				className={`zenModal ${zenModal ? 'zenModalOpen' : ''}`}
				onClick={handleZenModal}
			>
				<Suspense fallback={<span></span>}>
					<SelectZen
						setZenIcon={setZenIcon}
						setVideoPush={setVideoPush}
						setPoster={setPoster}
					/>
				</Suspense>
				<div className="zenModalBG"></div>
			</div>
			<div
				className={`videoIcon ${iconSH ? 'videoIconOpen' : ''}`}
				onClick={handleZenModal}
			>
				<img src={zenIcon} alt="zenIcon" />
				<div className="changeZen">切換情境</div>
				<span></span>
			</div>
			<div className={`zenTimeClose ${readyPop ? 'zenTimeShow' : ''}`}>
				<Suspense fallback={<span></span>}>
					<ZenTime handleCountStart={handleCountStart} />
				</Suspense>
			</div>

			<div className={`zenStartBG ${readyPop ? 'zenStartBGOpen' : ''}`}></div>

			<div className="videoContainer">
				<video
					autoPlay
					muted
					loop
					playsInline
					webkitplaysinline="true"
					type="video/mp4"
					src={videoPush}
					poster={poster}
					preload="metadata"
				/>
			</div>
		</>
	);
}

export default Zen;
