import React from 'react';
import './buddhaPlayer.css';
import BackPageButton from './backPageButton';
import HandlePlayer from './HandlePlayer';

function PlayerPage(props) {
	const buddhaName = props.match.params.buddhaname;
	const { titles, shareContent, id, contents } = props.location.state;

	console.log(props);

	return (
		<div className="playerPage">
			<BackPageButton playPageTitle={titles} />

			<HandlePlayer
				urlFB={buddhaName}
				scriptureID={id}
				title={titles}
				shareContent={shareContent}
				contents={contents}
			/>

			<div className="bottomSpace"></div>
		</div>
	);
}

export default PlayerPage;
