import React, { useState, useRef, useEffect } from 'react';

function PaymentInfo({
	handleChange,
	setSelectedValue,
	selectedValue,
	handleTotalCost,
	totalAll,
}) {
	// const [phoneCheck, setPhoneCheck] = useState();
	// const handlePhoneCheck = () => {
	// 	if (phoneCheck) {
	// 		return setPhoneCheck();
	// 	}
	// 	setPhoneCheck('0');
	// };
	// const phoneHight = useRef();
	const [checked, setChecked] = useState(true);

	function handleMonthlyChange(e) {
		handleTotalCost();
		handleChange(e);
		setSelectedValue(1);
	}

	function handleYearlyChange(e) {
		handleTotalCost();
		handleChange(e);
		setSelectedValue(12);
	}

	useEffect(() => {
		// console.log(selectedValue);
		handleTotalCost();
	}, [selectedValue]);

	return (
		<>
			<div className="buyDiv">
				<div className="titleText">
					付款方式
					<p>您可以選擇一年付，或每月轉帳。</p>
				</div>
				<div className="paySelect">
					<label className="container">
						12個月
						<input
							type="radio"
							name="entry.131897869"
							value="12個月"
							// defaultChecked={checked}
							// onChange={handleChange}
							defaultChecked={selectedValue === 12}
							onChange={handleYearlyChange}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<div className="paySelect">
					<label className="container">
						每月
						<input
							type="radio"
							name="entry.131897869"
							value="每月"
							// onChange={handleChange}
							defaultChecked={selectedValue === 1}
							onChange={handleMonthlyChange}
						/>
						<span className="checkmark"></span>
					</label>
					{/* <div
						className="payPhoneOpen"
						style={
							phoneCheck
								? { height: phoneHight.current.scrollHeight }
								: { height: '0px' }
						}
						ref={phoneHight}
					>
						<label className="phoneInput">
							電話：
							<input
								type="text"
								name="entry.1398786136"
								placeholder="到付請填電話方便郵差寄送"
								onChange={handleChange}
							/>
						</label>
					</div> */}
				</div>
			</div>
			<div className="buyDiv">
				<div className="titleText">
					收件人
					<div className="titleTextSub">
						誦經祈福全部完成後，會寄送手繪唐卡給您
					</div>
					<p>
						若填寫多人登記，請指定一位收件人。
						{/* <span>單人登記：</span>與祈福資料相同則不需填寫。 */}
					</p>
				</div>

				<div className="inputDiv">
					<label>
						收件姓名：
						<input
							type="text"
							name="entry.1502020246"
							// value={}
							onChange={handleChange}
						/>
					</label>
				</div>
				<div className="addressDiv">
					<label>
						收件地址：
						<textarea
							type="text"
							name="entry.1455886495"
							// value={sendAddress}
							onChange={handleChange}
						/>
					</label>
				</div>
			</div>
		</>
	);
}

export default PaymentInfo;
