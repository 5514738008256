import { Link } from 'react-router-dom';
import { useState } from 'react';
import './Navbar.css';
import { ReactComponent as MenuIcon } from '../../../svg/menu.svg';
import { ReactComponent as Logo } from '../../../svg/logo.svg';
import LineShareIcon from '../../../images/lineShareIcon.png';

function Navbar() {
	const [clickNav, setClickNav] = useState();
	const handleNav = () => {
		if (clickNav) {
			return setClickNav();
		}
		setClickNav('0');
	};

	function sendMail() {
		window.open(
			'mailto:zenentw@gmail.com?subject=我想詢問網站製作&body=您好：'
		);
	}
	return (
		<>
			<div className="navbar" onClick={handleNav}>
				<MenuIcon className="menuIcon" />
				<Logo className="navLogo" />
			</div>
			<div
				className={`navOpacity ${clickNav ? 'navOpacityOpen' : ''}`}
				onClick={handleNav}
			></div>

			<div className={`navClose ${clickNav ? 'navOpen' : ''}`}>
				<div to="/" className="navCloseBtn" onClick={handleNav}>
					×
				</div>
				<Link to="/bless-history">
					<p>‣ 廟宇巡禮</p>
				</Link>
				<Link to="/bless-zen">
					<p>‣ 冥想放鬆</p>
				</Link>
				<Link to="/bless-buyblees">
					<p>‣ 登記祈福</p>
				</Link>
				<Link to="/bless-order">
					<p>‣ 觀看紀錄</p>
				</Link>

				<button
					className="shareBtnNav"
					onClick={() => {
						window.open(
							`line://msg/text/${encodeURIComponent(
								'與你分享好聽的佛經樂曲，加入這個群組一起同修聽誦🙏' +
									'https://www.mday.tw/#/bless-share'
							)}`,
							'_blank'
						);
					}}
				>
					<img src={LineShareIcon} alt="bannerImg" />
					分享同修
				</button>

				<button
					className="addBtnNav"
					onClick={() => {
						window.open('https://lin.ee/RDN9UHU', '_blank');
					}}
				>
					{/* <img src={LineShareIcon} alt="bannerImg" /> */}
					收藏到 LINE
				</button>
				<div className="copyright">
					<div onClick={sendMail}>
						© 2020 善園 <br />
						網站由 天地一方 設計維護
						<br /> Email: zenentw@gmail.com
					</div>
				</div>
			</div>
		</>
	);
}

export default Navbar;
