import { ReactComponent as Logo } from '../../../svg/logo.svg';

function ShareNavbar() {
	return (
		<>
			<div className="navbar">
				<Logo className="navLogo" />
			</div>
		</>
	);
}

export default ShareNavbar;
