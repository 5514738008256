import React, { lazy, Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import './ListData.css';

import GptBanner from '../../../images/gptBanner.jpg';
import PayBuddha from '../../../images/payBuddha.png';
import PayTitle from '../../../images/payTitle.png';
import PayBuddhaBG from '../../../images/payBuddhaBg.jpg';
import AppleBtn from '../../../images/appleBtn.jpg';
import GoogleBtn from '../../../images/googleBtn.jpg';

function appIntro() {
	return (
		<div className="content">
			<div className="Bg">
				<img src={PayBuddhaBG} alt="ChatGPT_Banner" />
			</div>

			<div className="bannerTitle">
				<img src={PayTitle} alt="ChatGPT_Banner" />
				<div className="titleText">搭載ChatGPT-4的AI佛祖</div>
				<div className="titleText">百事可問，萬事有解。</div>
			</div>

			<div className="banner">
				<img src={PayBuddha} alt="ChatGPT_Banner" />
			</div>
			<a
				href="http://itunes.apple.com/app/id1621689168"
				target="_blank"
				className="linkBtn"
			>
				<div className="Btn">
					<img src={AppleBtn} alt="ChatGPT_Banner" />
				</div>
			</a>

			<a
				href="https://play.google.com/store/apps/details?id=com.pagepro.mdayapppublishapp"
				target="_blank"
				className="linkBtn"
			>
				<div className="Btn">
					<img src={GoogleBtn} alt="ChatGPT_Banner" />
				</div>
			</a>
		</div>
	);
}

export default appIntro;
