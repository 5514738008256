import React, { useState } from 'react';
import { ReactComponent as Arrow } from '../../../svg/arrow.svg';
import ProductModal from './ProductModal';

function FormInput({
	onTotalAll,
	handleTotalCost,
	handleNameCombo,
	handleProductCombo,
	handleBirthdayCombo,
	handleAddressCombo,
}) {
	const [modal, setModal] = useState();
	const handleModal = () => {
		if (modal) {
			return setModal() + handleTotalCost() + handleProductCombo();
		}
		setModal('0');
		handleTotalCost();
		handleProductCombo();
	};

	//費用總計;
	const [cost, setCost] = useState(0);

	//開運補財
	const [checkContent, setCheckContent] = useState('');
	const handleCheck = () => {
		let productName = '開運補財・';
		if (checkContent !== productName) {
			return (
				setCheckContent(productName) +
				setCost(cost + 299) +
				onTotalAll(+299) +
				handleProductCombo()
			);
		}
		setCheckContent('');
		setCost(cost - 299);
		onTotalAll(-299);
		handleProductCombo();
	};
	//消災增福
	const [checkContent2, setCheckContent2] = useState('');
	function handleCheck2() {
		let productName = '消災增福・';
		if (checkContent2 !== productName) {
			return (
				setCheckContent2(productName) +
				setCost(cost + 299) +
				onTotalAll(+299) +
				handleProductCombo()
			);
		}
		setCheckContent2('');
		setCost(cost - 299);
		onTotalAll(-299);
		handleProductCombo();
	}
	//文殊智慧
	const [checkContent3, setCheckContent3] = useState('');
	function handleCheck3() {
		let productName = '文殊智慧・';
		if (checkContent3 !== productName) {
			return (
				setCheckContent3(productName) +
				setCost(cost + 299) +
				onTotalAll(+299) +
				handleProductCombo()
			);
		}
		setCheckContent3('');
		setCost(cost - 299);
		onTotalAll(-299);
		handleProductCombo();
	}
	//禮懺誦戒
	const [checkContent4, setCheckContent4] = useState('');
	function handleCheck4() {
		let productName = '禮懺誦戒・';
		if (checkContent4 !== productName) {
			return (
				setCheckContent4(productName) +
				setCost(cost + 299) +
				onTotalAll(+299) +
				handleProductCombo()
			);
		}
		setCheckContent4('');
		setCost(cost - 299);
		onTotalAll(-299);
		handleProductCombo();
	}
	//全項登記
	const [checkContent5, setCheckContent5] = useState('');
	function handleCheck5() {
		let productName = '全項登記';
		if (checkContent5 !== productName) {
			return (
				setCheckContent5(productName) +
				setCost(cost + 1100) +
				onTotalAll(+1100) +
				handleProductCombo()
			);
		}
		setCheckContent5('');
		setCost(cost - 1100);
		onTotalAll(-1100);
		handleProductCombo();
	}

	const [product, setProduct] = useState();
	const productDisplay = { display: 'inline-block' };
	const handleProduct = () => {
		setProduct(productDisplay);
	};

	return (
		<>
			<div className="tipDel">
				<span className="productTotal">
					費用：
					<span id="productTotal" className="costTotal">
						{cost} / 月
					</span>
				</span>
			</div>
			<div className="productDiv" onClick={handleProduct}>
				<label>
					祈福項目
					<span>
						<Arrow className="arrowBuy" />
					</span>
					<textarea
						required="required"
						name="entry.1806381009"
						className="productInput"
						id="productInput"
						style={product}
						placeholder="點擊選擇"
						readOnly="readonly"
						type="text"
						value={
							checkContent +
							checkContent2 +
							checkContent3 +
							checkContent4 +
							checkContent5
						}
						onClick={handleModal}
						onChange={handleProductCombo}
					/>
				</label>
			</div>
			<div className="inputDiv">
				<label>
					祈福對象：
					<input
						required="required"
						id="enterName"
						type="text"
						placeholder="請填寫一位姓名"
						name="entry.812450364"
						onChange={handleNameCombo}
					/>
				</label>
			</div>
			<div className="inputDiv">
				<label>
					對象生日：
					<input
						required="required"
						type="text"
						placeholder="農曆、國曆皆可"
						name="entry.1147185105"
						// value={formData['entry.1147185105'] || ''}
						onChange={handleBirthdayCombo}
					/>
				</label>
			</div>
			<div className="addressDiv">
				<label>
					地址：
					<textarea
						type="text"
						name="entry.790714652"
						// required="required"
						placeholder="誦念唱名時能更準確的迴向"
						onChange={handleAddressCombo}
					/>
				</label>
			</div>

			{/* PRODUCT MODAL */}
			<ProductModal
				handleCheck={handleCheck}
				handleCheck2={handleCheck2}
				handleCheck3={handleCheck3}
				handleCheck4={handleCheck4}
				handleCheck5={handleCheck5}
				modal={modal}
				handleModal={handleModal}
			/>
		</>
	);
}

export default FormInput;
