export const dataBless = [
	{
		title: '第二十五站－鹽行行天宮',
		imgTitle: 'image/blessImg/blessDate20226.png',
		content:
			'鹽行天后宮前身洲仔尾天后宮是臺灣縣武定里公廟，其遶境為不定期舉辦，舉行時間大多在媽祖誕辰前後，媽祖信徒可關注遶境日期共享盛舉。',
		listImg: 'image/blessImg/bless20226img.jpg',
		god: '主祀：天上聖母(媽祖)',
		date: '建於1696年',
		address: '・地址：臺南市永康區鹽行里中正南路690號',
	},
	{
		title: '第二十四站－彌陀寺',
		imgTitle: 'image/blessImg/blessDate20225.png',
		content:
			'彌陀寺為臺南四大古剎之一，最初創建時稱為彌陀室，在擴建之後才被稱為彌陀寺，由於疫情原因，目前一樓主祀殿堂與二樓千手觀音能在殿外參拜暫不開放。',
		listImg: 'image/blessImg/bless20225img.jpg',
		god: '主祀：阿彌陀佛',
		date: '建於1647年-1661年',
		address: '・地址：臺南市東區東門里東門路一段133號',
	},
	{
		title: '第二十三站－慈雲寺',
		imgTitle: 'image/blessImg/blessDate20224.png',
		content:
			'緣由大岡山超峰寺迎請觀世音菩薩供奉膜拜，多年後菩薩顯靈，遠近相傳。供奉主神開基觀世音菩薩，信眾尊稱正尊佛祖。四月師父於此代持誦經祈福迴向。',
		listImg: 'image/blessImg/bless20224img.jpg',
		god: '主祀：觀世音菩薩',
		date: '1945年創立',
		address: '・地址：臺南市北區公園路延平里746巷45號',
	},
	{
		title: '第二十二站－保安宮',
		imgTitle: 'image/blessImg/blessDate20223.png',
		content:
			'南廠保安宮距今有三百多年歷史，初始供奉李府千歲，後於王爺指示挖出「贔屭」名曰白蓮聖母，並運往此廟安置，現供奉五府王爺(李、池、吳、朱、范)，三月師父於此向五府王爺與白蓮聖母座前誦念經文迴向。',
		listImg: 'image/blessImg/bless20223img.jpg',
		god: '主祀：五府王爺',
		date: '1663年建廟',
		address: '・地址：臺南市中西區保安路90號',
	},
	{
		title: '第二十一站－福德祠',
		imgTitle: 'image/blessImg/blessDate20222.png',
		content:
			'小南天福德祠距今已有三百多年歷史，主祀為福德正神，也是全台南首間土地公廟宇，二月於此誦念經文唱名、地址迴向，祈求土地公護佑各位師兄師姐家興事業旺。',
		listImg: 'image/blessImg/bless20222img.jpg',
		god: '主祀：福德正神',
		date: '1666年建廟',
		address: '・地址：臺南市中西區忠義路二段158巷27號',
	},
	{
		title: '第二十站－城隍廟',
		imgTitle: 'image/blessImg/blessDate20221.png',
		content:
			'此廟是臺灣最早的官建城隍廟，建於明朝永曆年間，高懸於三川殿上的名匾「爾來了」，與台南天壇的「一」字匾、竹溪寺的「了然世界」匾、祀典武廟的「大丈夫」匾額合稱「臺南四大名匾」，1月於城隍爺與觀音菩薩座前誦念經文唱名迴向，福佑良善諸位師兄師姐虎年順遂消災解厄。',
		listImg: 'image/blessImg/bless20221img.jpg',
		god: '主祀：城隍爺威靈公',
		date: '1669年建廟',
		address: '・地址：臺南市中西區城隍里青年路133號',
	},
	{
		title: '第十九站－天壇',
		imgTitle: 'image/blessImg/blessDate12.png',
		content:
			'台灣天公三間半，分別是台南天壇、沙鹿玉皇殿、新竹天公壇，十二月於天壇玉皇上帝、文衡聖帝座前持唸誦經、唱名迴向，師父也為大家代添香油，並領取天壇護身符寄回給各位師兄師姐。',
		listImg: 'image/blessImg/bless12img.jpg',
		god: '主祀：玉皇上帝',
		date: '1854年建廟',
		address: '・地址：臺南市中西區忠義路二段84巷16號',
	},
	{
		title: '第十八站－武廟',
		imgTitle: 'image/blessImg/blessDate11.png',
		content:
			'於關聖帝君、釋迦牟尼佛座前，誦念經文唱名迴向，適逢新年將至，代添香油領取春聯，隨信附上寄回給各位師兄師姐。',
		listImg: 'image/blessImg/bless11img.jpg',
		god: '主祀：關聖帝君',
		date: '1665年創立',
		address: '・地址：臺南市中西區永福路2段229號',
	},
	{
		title: '第十七站－聖母廟',
		imgTitle: 'image/blessImg/blessDate10.png',
		content:
			'師父於媽祖、釋迦牟尼佛座前，誦念經文一一唱名迴向，燃香上稟諸位所求事項，並代為添香油、燒金謝恩。',
		listImg: 'image/blessImg/bless10img.jpg',
		god: '主祀：天上聖母',
		date: '1719年創立',
		address: '・地址：臺南市安南區城安路160號',
	},
	{
		title: '第十六站－靈山慈惠堂',
		imgTitle: 'image/blessImg/blessDate9.png',
		content:
			'與郭總幹事聊天中得知該廟起源，確實神蹟顯赫，師父於驪山老母座前為諸位誦經祈福，再於福德正神座前持念唱名，恭請神祇護佑諸位居家安康。',
		listImg: 'image/blessImg/bless9img.jpg',
		god: '主祀：驪山老母',
		date: '民國60年創立',
		address: '・地址：臺南市新化區中興路東榮里1鄰108之1號',
	},
	{
		title: '第十五站－開元寺',
		imgTitle: 'image/blessImg/blessDate8.png',
		content:
			'開元寺已有三百多年歷史，師父分別於彌勒佛與千手觀音座前持念迴向，並將疏文恭奉於桌前，開元寺為佛教寺廟，虔心誦念即可，寺中不燒金紙。',
		listImg: 'image/blessImg/bless8img.jpg',
		god: '主祀：千手觀音',
		date: '1690年創立',
		address: '・地址：臺南市北區北園街89號',
	},
	{
		title: '第十四站－興濟宮',
		imgTitle: 'image/blessImg/blessDate7.png',
		content:
			'先於佛祖座前誦念經文，再於保生大帝座前唱名一一迴向，每間廟宇都會代各位添香油、供奉花果，燃香參拜廟中諸神祇，最後再燒金謝恩。',
		listImg: 'image/blessImg/bless7img.jpg',
		god: '主祀：保生大帝',
		date: '1678年創立',
		address: '・地址：臺南市北區成功路86號',
	},
	{
		title: '第十三站－天后宮',
		imgTitle: 'image/blessImg/blessDate6.png',
		content:
			'連續兩個月都在天后宮誦經祈福唱名迴向，此時正逢疫情嚴峻，也向媽祖祈禱保佑全台平安。',
		listImg: 'image/blessImg/bless6img.jpg',
		god: '主祀：天上聖母',
		date: '1661年創立',
		address: '・地址：臺南市安南區媽祖宮一街136號',
	},
	{
		title: '第十二站－天后宮',
		imgTitle: 'image/blessImg/blessDate5.png',
		content:
			'師父先於媽祖座前持念唱名，再於關聖帝君座前，為登記開運補財的諸位誦念迴向，每張寫有資料的疏文都會一一過爐繞香火。',
		listImg: 'image/blessImg/bless5img.jpg',
		god: '主祀：天上聖母',
		date: '1661年創立',
		address: '・地址：臺南市安南區媽祖宮一街136號',
	},
	{
		title: '第十一站－陵興宮',
		imgTitle: 'image/blessImg/blessDate4.png',
		content:
			'府城有許多祭祀王爺的廟宇，陵興宮的王爺是由聖母廟恭請祀奉，師父先將疏文恭奉於王爺桌前，再一一誦念唱名迴向。',
		listImg: 'image/blessImg/bless4img.jpg',
		god: '主祀：吳府千歲',
		date: '民國64年草創',
		address: '・地址：臺南市北區育德二路812號',
	},
	{
		title: '第十站－南天府',
		imgTitle: 'image/blessImg/blessDate3.png',
		content:
			'透過訪問當地里長與廟中總幹事，得知該廟建地是從基督信仰的地主手中購得，並由關聖帝君降駕指示而成，應驗了人有誠心必得神助。',
		listImg: 'image/blessImg/bless3img.jpg',
		god: '主祀：關聖帝君',
		date: '民國55年創立',
		address: '・地址：臺南市東區裕豐街裕農里214巷25號',
	},
	{
		title: '第九站－妙心寺',
		imgTitle: 'image/blessImg/blessDate2.png',
		content:
			'師父於釋迦牟尼佛與文殊菩薩座前持念經文，手持每張寫有資料的疏文一一唱名迴向，妙心寺為佛教廟宇，恭敬虔心即可，不燒金紙。',
		listImg: 'image/blessImg/bless2img.jpg',
		god: '主祀：釋迦牟尼佛',
		date: '1958年創立',
		address: '・地址：臺南市永康區勝利街勝利里11巷10號',
	},
	{
		title: '第八站－護東宮',
		imgTitle: 'image/blessImg/blessDate1.png',
		content:
			'將每個人的疏文恭奉於王爺桌前，燃香祭拜稟明來意，再於王爺與觀世音菩薩座前誦念經文，唱名疏文姓名資料一一迴向。',
		listImg: 'image/blessImg/bless1img.jpg',
		god: '主祀：五府千歲',
		date: '1975年創立',
		address: '・地址：臺南市東區小東路小東里198巷40弄52號',
	},
	{
		title: '第七站－鎮南宮',
		imgTitle: 'image/blessImg/blessDate202012.png',
		content:
			'在廟公的協助下，上稟來意，並擲筊請示王爺可誦念何種經文，得王爺聖杯指示後再持念迴向，並將疏文一一過爐，護佑諸位所求如願。',
		listImg: 'image/blessImg/bless012img.jpg',
		god: '主祀：池府千歲',
		date: '1909年創立',
		address: '・地址：臺南市北區北園街開元里124巷7號',
	},
];
