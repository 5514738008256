import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import './ListData.css';
import Navbar from './Navbar';
import { dataBuddhaName } from './scriptureData/dataBuddhaName';
import { dataClass } from './scriptureData/dataClass';
import { dataHeart } from './scriptureData/dataHeart';
import BannerImg from '../../../images/banner.jpg';

const NameItem = lazy(() => import('./NameItem'));
const ClassItem = lazy(() => import('./ClassItem'));
const HeartItem = lazy(() => import('./HeartItem'));

function ListData() {
	//控制 佛號 列表
	const [nameList, setNameList] = useState('0');
	const handleNameList = (index) => {
		if (nameList === index) {
			return setNameList('0');
		}
		setNameList(index);
	};
	//控制 經文 列表
	const [classList, setClassList] = useState('0');
	const handleClassList = (index) => {
		if (classList === index) {
			return setClassList('0');
		}
		setClassList(index);
	};
	//控制 心咒 列表
	const [heartList, setHeartList] = useState('0');
	const handleHeartList = (index) => {
		if (heartList === index) {
			return setHeartList('0');
		}
		setHeartList(index);
	};

	return (
		<div>
			<Navbar />
			<Link to="/bless-history">
				<div className="banner">
					<img src={BannerImg} alt="bannerImg" />
				</div>
			</Link>
			<Link to="/bless-zen">
				<div className="gotoZen">前往冥想：平靜、放鬆、睡眠</div>
			</Link>
			<div className="countTotal">
				累計聽誦
				<span>
					{localStorage.getItem('countTotal')}
					<span className="countFont">/遍</span>
				</span>
			</div>

			<span className="countLine"></span>
			<div className="ulspace">
				<ul className="buddhaName">
					<Suspense fallback={<p>諸佛聖號讀取中...</p>}>
						諸佛聖號
						{dataBuddhaName.map((nameData, index) => (
							<NameItem
								toToggle={() => handleNameList(index)}
								active={nameList === index}
								key={index}
								nameDataAll={nameData}
							/>
						))}
					</Suspense>
				</ul>

				<ul className="buddhaScriptures">
					<Suspense fallback={<p>佛經樂曲讀取中...</p>}>
						佛經樂曲
						{dataClass.map((classData, index) => (
							<ClassItem
								toToggle={() => handleClassList(index)}
								active={classList === index}
								key={index}
								classDataAll={classData}
							/>
						))}
					</Suspense>
				</ul>

				<ul className="buddhaHeart">
					<Suspense fallback={<p>心咒經典讀取中...</p>}>
						心咒經典
						{dataHeart.map((heartData, index) => (
							<HeartItem
								toToggle={() => handleHeartList(index)}
								active={heartList === index}
								key={index}
								heartDataAll={heartData}
							/>
						))}
					</Suspense>
				</ul>
			</div>
			<div className="bottomSpace"></div>
		</div>
	);
}

export default ListData;
