import React, { Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import './ListData.css';
import Navbar from './Navbar';
import { dataBuddhaName } from './scriptureData/dataBuddhaName';
import { dataClass } from './scriptureData/dataClass';
import { dataHeart } from './scriptureData/dataHeart';
import LineShareIcon from '../../../images/lineShareIcon.png';
import BannerImg from '../../../images/banner.jpg';
const NameItem = lazy(() => import('./NameItem'));
const ClassItem = lazy(() => import('./ClassItem'));
const HeartItem = lazy(() => import('./HeartItem'));

function ListDataADpage() {
	//控制 佛號 列表
	const [nameList, setNameList] = useState('0');
	const handleNameList = (index) => {
		if (nameList === index) {
			return setNameList('0');
		}
		setNameList(index);
	};
	//控制 經文 列表
	const [classList, setClassList] = useState('0');
	const handleClassList = (index) => {
		if (classList === index) {
			return setClassList('0');
		}
		setClassList(index);
	};
	//控制 心咒 列表
	const [heartList, setHeartList] = useState('0');
	const handleHeartList = (index) => {
		if (heartList === index) {
			return setHeartList('0');
		}
		setHeartList(index);
	};

	const [zenStart, setZenStart] = useState();
	const handleZenStart = () => {
		if (zenStart) {
			return setZenStart();
		}
		setZenStart('0');
	};

	return (
		<div>
			<Navbar />
			<Link to="/bless-history">
				<div className="banner">
					<img src={BannerImg} alt="bannerImg" />
				</div>
			</Link>

			<div
				style={{ position: 'fixed', zIndex: '999' }}
				className={`zenStart ${zenStart ? 'zenStartClose' : ''}`}
				onClick={handleZenStart}
			>
				<div className="zenFirst">
					<div className="zenFirstContent">
						<div
							className="nullValues"
							style={{ position: 'revert', margin: 'auto' }}
						>
							<span style={{ fontSize: '18px' }}>感恩師兄師姐來到佛經樂曲</span>
							<br />
							<br />
							您可以向下滑動，選擇要聽誦的經典，按下
							<span style={{ color: '#750606' }}>播放聽誦</span>即可。
							<br />
							<br />
							聽誦完畢會自動幫您紀錄次數。
							<br />
							<br />
							您也可以將此頁面收藏到LINE，方便日後隨時開啟。
							<br />
							<br />
							<span>日日積累，福德財智皆具足</span>
							<br />
							<button className="goBuyPage" onClick={handleZenStart}>
								開始
							</button>
						</div>
					</div>
					<div className="zenModalBG"></div>
				</div>
			</div>

			<div className="countTotal">
				累計聽誦
				<span>
					{localStorage.getItem('countTotal')}
					<span className="countFont">/遍</span>
				</span>
			</div>

			<span className="countLine"></span>
			<div className="ulspace">
				<ul className="buddhaName">
					<Suspense fallback={<p>諸佛聖號讀取中...</p>}>
						諸佛聖號
						{dataBuddhaName.map((nameData, index) => (
							<NameItem
								toToggle={() => handleNameList(index)}
								active={nameList === index}
								key={index}
								nameDataAll={nameData}
							/>
						))}
					</Suspense>
				</ul>

				<ul className="buddhaScriptures">
					<Suspense fallback={<p>佛經樂曲讀取中...</p>}>
						佛經樂曲
						{dataClass.map((classData, index) => (
							<ClassItem
								toToggle={() => handleClassList(index)}
								active={classList === index}
								key={index}
								classDataAll={classData}
							/>
						))}
					</Suspense>
				</ul>

				<ul className="buddhaHeart">
					<Suspense fallback={<p>心咒經典讀取中...</p>}>
						心咒經典
						{dataHeart.map((heartData, index) => (
							<HeartItem
								toToggle={() => handleHeartList(index)}
								active={heartList === index}
								key={index}
								heartDataAll={heartData}
							/>
						))}
					</Suspense>
				</ul>

				<div className="lineFixedDiv">
					<button
						style={{ borderRadius: '30px' }}
						className="lineShareFixed"
						onClick={() => {
							window.open('https://lin.ee/RDN9UHU', '_blank');
						}}
					>
						<img src={LineShareIcon} alt="line share" />
						將此頁加入 LINE 方便聽誦
					</button>
				</div>
			</div>
			<div className="bottomSpace"></div>
		</div>
	);
}

export default ListDataADpage;
