export const dataBuddhaName = [
	{
		title: '釋迦牟尼佛聖號',
		imgTitle: 'image/nameShi.png',
		content:
			'釋迦牟尼佛是如來佛、佛祖，為世間最尊貴者，弟子也稱其為世尊，常聽誦此聖號，可以離苦得樂，引導你走向光明大道不墮惡道。',
		listImg: 'image/nameBuddha1.jpg',
		link: '/934985397115124',
		id: 'scriptureNameID1',
		share: '願你得佛祖護佑，前程光明無阻。',
	},
	{
		title: '阿彌陀佛聖號',
		imgTitle: 'image/nameA.png',
		content:
			'阿彌陀佛為壽命無量佛、光明無量佛的意思，隨時聽誦能幫助我們驅除邪穢，淨化自身磁場增加信心，事事逢凶化吉。',
		listImg: 'image/nameBuddha2.jpg',
		link: '/277356527783683',
		id: 'scriptureNameID2',
		share: '願你信心不滅，逢凶化吉。',
	},
	{
		title: '藥師佛聖號',
		imgTitle: 'image/nameYao.png',
		content:
			'藥師佛也稱消災延壽藥師佛，「藥」其中之一意思是醫人心、離輪迴，時常聽誦可為我們解除疾苦，皆得解脫內心憂煩得身心清淨。',
		listImg: 'image/nameBuddha3.jpg',
		link: '/1671758263216029',
		id: 'scriptureNameID3',
		share: '願你身心健康，解除一切憂苦。',
	},
	{
		title: '地藏王菩薩聖號',
		imgTitle: 'image/nameDe.png',
		content:
			'地藏王菩薩為佛教四大菩薩之一，日日聽誦可令家人萬罪消滅萬福來臨，若懷孕中為胎兒誦念，可使其相貌端正，出生之後安樂易養健康長壽。',
		listImg: 'image/nameBuddha4.jpg',
		link: '/1361560604301243',
		id: 'scriptureNameID4',
		share: '願你得地藏菩薩護持，消業障平安健康。',
	},
	{
		title: '觀世音菩薩聖號',
		imgTitle: 'image/nameGuan.png',
		content:
			'觀音菩薩說：當人遭受苦惱恐怖，憂愁孤獨貧窮困苦時，凡稱念我的名號，那求救的音聲但被我所聞，則為其免除種種。',
		listImg: 'image/nameBuddha5.jpg',
		link: '/908891383320723',
		id: 'scriptureNameID5',
		share: '願菩薩為你解除一切黑暗恐怖與不安。',
	},
];
