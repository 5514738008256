import React from 'react';
import { ReactComponent as ProductDec } from '../../../svg/productModalDec.svg';

function ProductModal({
	modal,
	handleCheck,
	handleCheck2,
	handleCheck3,
	handleCheck4,
	handleCheck5,
	handleModal,
}) {
	return (
		<>
			<div
				className={`modalOpacity ${modal ? 'modalOpacityOpen' : ''}`}
				onClick={handleModal}
			></div>
			<div className={`modalClose ${modal ? 'modalOpen' : ''}`}>
				<div className="modalDecUp">
					<span className="decLeft">
						<ProductDec />
					</span>
					<span className="decRight">
						<ProductDec />
					</span>
				</div>
				<label className="container">
					<div className="product">
						開運補財<span className="productSub">(添財庫)</span>
						<div>
							<span className="productCost">
								299/<span>月</span>
							</span>
							<input
								id="product1"
								name="開運補財"
								type="checkbox"
								onClick={handleCheck}
							/>
							<span className="checkmark"></span>
						</div>
					</div>
				</label>
				<span className="productLine"></span>
				<label className="container">
					<div className="product">
						消災增福 <span className="productSub">(消災厄)</span>
						<div>
							<span className="productCost">
								299/<span>月</span>
							</span>
							<input
								id="product2"
								name="消災增福"
								type="checkbox"
								onClick={handleCheck2}
							/>
							<span className="checkmark"></span>
						</div>
					</div>
				</label>
				<span className="productLine"></span>
				<label className="container">
					<div className="product">
						文殊智慧 <span className="productSub">(開智慧)</span>
						<div>
							<span className="productCost">
								299/<span>月</span>
							</span>
							<input
								id="product3"
								name="文殊智慧"
								type="checkbox"
								onClick={handleCheck3}
							/>
							<span className="checkmark"></span>
						</div>
					</div>
				</label>
				<span className="productLine"></span>
				<label className="container">
					<div className="product">
						禮懺誦戒 <span className="productSub">(解業障)</span>
						<div>
							<span className="productCost">
								299/<span>月</span>
							</span>
							<input
								id="product4"
								name="禮懺誦戒"
								type="checkbox"
								onClick={handleCheck4}
							/>
							<span className="checkmark"></span>
						</div>
					</div>
				</label>
				<span className="productLine"></span>
				<label className="container">
					<div className="product">
						全項登記
						<div>
							<span className="productCost">
								1100/<span>月</span>
							</span>

							<input
								id="product5"
								name="全項登記"
								type="checkbox"
								onClick={handleCheck5}
							/>
							<span className="checkmark"></span>
						</div>
					</div>
				</label>
				<div className="modalFinish" onClick={handleModal}>
					完成
				</div>
				<div className="modalDecDown">
					<span className="decLeftDown">
						<ProductDec />
					</span>
					<span className="decRightDown">
						<ProductDec />
					</span>
				</div>
			</div>
		</>
	);
}

export default ProductModal;
