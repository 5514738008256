import React, { useRef, useEffect } from 'react';
import { ReactComponent as Arrow } from '../../../svg/arrow.svg';
import './blessList.css';

function BlessItem({ blessDataAll, toToggle, active }) {
	const { title, imgTitle, content, listImg, god, date, address } =
		blessDataAll;
	const blessEl = useRef();

	useEffect(() => {
		return (document.getElementById('firstOpen').style.height = 'auto');
	}, []);

	console.log('bless');

	return (
		<>
			<li onClick={toToggle} className="blessList">
				<div className="blessImgDiv">
					<img className="blessImage" src={imgTitle} alt="buddha" />
				</div>
				<div>
					<p className="secondTitle">府城廟宇巡禮．{date}</p>
					{title}
				</div>

				<span>
					<Arrow className={`arrowBless ${active ? 'arrowUpBless' : ''}`} />
				</span>
			</li>
			<span className="blessLine"></span>
			<div
				style={
					active ? { height: blessEl.current.scrollHeight } : { height: '0px' }
				}
				className="blessContentIntro"
				id="firstOpen"
				ref={blessEl}
			>
				<div className="blessContent">
					<span>{god}</span>
					<br />
					{content}
					<br />
					<div className="address">{address}</div>
				</div>
				<img className="blessListImg" src={listImg} alt="buddha" />
			</div>
		</>
	);
}

export default BlessItem;
