export const dataClass = [
	{
		title: '般若波羅蜜多心經',
		imgTitle: 'image/classHeart.png',
		content:
			'心經為六百卷般若經典之精華，時常聽誦可以幫我們破除三障，同時也能幫助事業財運得以順利，心經也是諸多海內外企業家名人最為推崇的經典。',
		listImg: 'image/classBuddha1.jpg',
		link: '/641050840411317',
		id: 'scriptureClassID1',
		share: '助你破除三障，事業圓滿財福具足。',
	},
	{
		title: '金剛經',
		imgTitle: 'image/classGold.png',
		content:
			'金剛經為釋迦牟尼佛宣說的經典，聽聞此經所獲功德勝於用全世界的七寶來佈施，可消過去世業障，獲得今世福報，其殊勝利益不可思議。',
		listImg: 'image/classBuddha2.jpg',
		link: '/429656715359746',
		id: 'scriptureClassID2',
		share: '助你消除業障，得諸金剛菩薩護持。',
	},
	{
		title: '南無妙法蓮華經(日語)',
		imgTitle: 'image/classMiao.png',
		content:
			'南無妙法蓮華經為日蓮宗的修持經典，聽誦可得諸佛護念，一切魔外不得其便，一切煩惱所不能侵，現世安穩，所願不虛。',
		listImg: 'image/classBuddha3.jpg',
		link: '/438489947741596',
		id: 'scriptureClassID3',
		share: '使你得諸佛護念得無量功德福報。',
	},
	// {
	// 	title: '佛說阿彌陀經',
	// 	imgTitle: 'image/classFu.png',
	// 	content:
	// 		'為釋迦牟尼佛主動說法的經文，其中更有無量十方諸佛聖號，故自古即有能獲得無量無邊功德，並能得到諸佛護持的殊勝。',
	// 	listImg: 'image/classBuddha4.jpg',
	// 	link: '/2915770055407936',
	// 	id: 'scriptureClassID4',
	// 	share: '使你得到殊勝利益諸佛護持。',
	// },
	// {
	// 	title: '文殊菩薩祈請文',
	// 	imgTitle: 'image/classWen.png',
	// 	content:
	// 		'也是藏傳中的「吉祥本智功德賢讚」，此經典可去除一切煩惱障，使人心生信心不滅，開啟無盡的智慧寶藏。',
	// 	listImg: 'image/classBuddha5.jpg',
	// 	link: '/275981037895109',
	// 	id: 'scriptureClassID5',
	// 	share: '助你開啟無盡的智慧寶藏。',
	// },
	{
		title: '關聖帝君桃園明聖經',
		imgTitle: 'image/classMing.png',
		content:
			'關聖帝君也是武財神，此經可化解現時所遇種種困境，助人財運亨通，時常聽誦亦可使人生浩然正氣，使邪魔不可侵犯。',
		listImg: 'image/classBuddha6.jpg',
		link: '/596426618304193',
		id: 'scriptureClassID6',
		share: '助你財運亨通事業順利。',
	},
	{
		title: '彌勒救苦真經',
		imgTitle: 'image/classMi.png',
		content:
			'此經在於幫助人們出脫人生種種苦，生死苦、身心苦、劫難苦，時常聽誦可以消災祈福，逢凶化吉避開劫難。',
		listImg: 'image/classBuddha7.jpg',
		link: '/1510619092640344',
		id: 'scriptureClassID7',
		share: '為你祈福消災護佑平安。',
	},
];
