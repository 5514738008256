import React, { useState, useEffect } from 'react';
import BackOrderPage from './backOrderPage';
import './order.css';
import { Link } from 'react-router-dom';

function Order() {
	const [call, setCall] = useState([]);
	const filteredLS = () => {
		var filteredObj = Object.keys(localStorage)
			.filter(function (key) {
				return key.match(/^buy/g);
			})
			.map(function (key) {
				return JSON.parse(localStorage.getItem(key));
			});
		if (filteredObj == '') {
			return handleNull();
		}
		setCall(filteredObj);
		// console.log(filteredObj);
	};

	const [nullValue, setNullValue] = useState(false);
	const handleNull = () => {
		setNullValue(true);
	};

	useEffect(() => {
		filteredLS();
		console.log(call);
		return () => {
			// cleanup
		};
	}, []);

	return (
		<div className="orderDiv">
			<BackOrderPage playPageTitle="登記記錄" />

			{nullValue && (
				<div className="nullValues">
					您尚未有登記的資料，請先前往<span>登記祈福</span>，感恩。
					<Link to="/bless-buyblees">
						<button className="goBuyPage">前往登記</button>
					</Link>
				</div>
			)}
			<ul className="orderUl">
				{call.map(
					({
						keyDate,
						nameAll,
						productAll,
						birthdayAll,
						addressAll,
						orderDate,
						'entry.131897869': pay,
						'entry.589267785': totalCost,
					}) => (
						<div className="orderContent" key={keyDate}>
							<div className="orderDate">{orderDate}登記</div>
							<p className="orderTitle">登記項目：</p>
							<div className="orderIntro">{productAll}</div>
							<span className="orderLine"></span>

							<p className="orderTitle">登記姓名：</p>
							<div className="orderIntro">{nameAll}</div>
							<span className="orderLine"></span>

							<p className="orderTitle">登記生日：</p>
							<div className="orderIntro">{birthdayAll}</div>
							<span className="orderLine"></span>

							<p className="orderTitle">登記地址：</p>
							<div className="orderIntro">{addressAll}</div>

							<div className="payIntro">
								付費方式：{pay}
								<span>總計：{totalCost}</span>
							</div>
							<div className="payTransfer">
								<span>轉帳帳號：</span>
								若您使用轉帳方式，請轉帳完成後，於 LINE 告知帳號後三碼即可。
							</div>
							<div className="payInformation">
								<p>007 第一銀行大安分行</p>
								<p>戶名：范世岳</p>
								<p className="payNum">帳號：161-68-109162</p>
							</div>
						</div>
					)
				)}
			</ul>

			{/* <div className="orderBottomSpace"></div> */}
		</div>
	);
}

export default Order;
